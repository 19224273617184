import type { FilterClaimsQuery, GetClaimByIdQuery } from "#gql";
import { defineStore } from "pinia";

import type { ClaimComplete, ClaimFiltered } from "~/types";

export type ClaimUiState = {
  sourcesEdited: boolean;
  sourceIds: string | string[];
  submitterNotes?: string | string[];
};
export type ClaimUiStateKey = "sourceIds" | "submitterNotes";

type ClaimIds = {
  shortId: { short: string; long: string };
  processId: string;
};

export type Claim =
  | (Omit<Exclude<GetClaimByIdQuery["claim"], undefined | null>, "shortId" | "processId"> & ClaimIds)
  | (Omit<FilterClaimsQuery["data"][number], "shortId" | "processId"> & ClaimIds);

export const useClaimStore = defineStore("claim", {
  state: () => ({
    claims: {} as { [id: string]: ClaimUiState },
    claim: null as Claim | null
  }),
  actions: {
    addClaim(claimId: string) {
      if (this.claims[claimId]) return;
      this.claims[claimId] = {
        sourcesEdited: false,
        sourceIds: [],
        submitterNotes: []
      };
    },
    // Add a source | origin | submitterNotes ID to a claim
    addId(claimId: string, key: ClaimUiStateKey, value: string) {
      if (!this.claims[claimId]) this.addClaim(claimId);
      // Ensure that the addition of sourceIds is reactive
      this.claims[claimId] = {
        ...this.claims[claimId],
        [key]: this.claims[claimId][key] ? [...this.claims[claimId][key], value] : [value]
      };
    },
    setClaim(claim: ClaimComplete | ClaimFiltered, type: "claims" | "submissions") {
      if (!claim) return;
      const chatStore = useChatStore();
      chatStore.roomName = (claim?.shortId ?? "")?.replaceAll("/", "-");

      const { $i18n } = useNuxtApp();
      const processId = `${$i18n.t("claim.title")} #${claim.processId}`;
      const shortId = normalizeShortId($i18n.locale.value, claim.shortId);

      const navigationStore = useNavigationStore();
      navigationStore.pageTitle = type === "claims" ? processId : shortId.short;

      this.claim = {
        ...claim,
        processId,
        shortId
      };
    }
  }
});
