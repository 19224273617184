import { type QueryKey, type InfiniteData, useQueryClient } from "@tanstack/vue-query";

import type { MutationMap } from "./types";

type Page<T> = {
  data: T[];
  nextCursor: number | undefined;
};

export default function useCacheInfinityOptimisticUpdate<Vars, Data>(
  matchKey: keyof Data,
  optimisticUpdatePlan: (vars: Vars, draft: Data) => void
) {
  const queryClient = useQueryClient();

  const onMutate = async (
    vars: Vars,
    affectedCacheKeys: QueryKey[],
    mutationMap: MutationMap = [],
    matchValue: unknown
  ) => {
    const _mutationMap: MutationMap = [...mutationMap];
    // iterate through all the cache keys and update the cache
    for (const key of affectedCacheKeys) {
      const previousDataList = queryClient.getQueriesData<InfiniteData<Page<Data>>>({ queryKey: key });
      for (const [subKey, previousData] of previousDataList) {
        console.debug("useCacheInfinityOptimisticUpdate: onMutate: subkey", key);
        if (!previousData || !previousData.pages) continue; // Nodata found so nothing to  update
        // Search for item is the previous data
        const indices = findItemInPages(previousData.pages, matchKey, matchValue);
        if (indices) {
          const newData = structuredClone(previousData);
          const item = getItemFromPage(newData.pages, indices.pageIndex, indices.dataIndex);
          await queryClient.cancelQueries({ queryKey: subKey });
          optimisticUpdatePlan(vars, item);
          queryClient.setQueryData(subKey, newData);
          _mutationMap.push({ key: subKey, previousData });
        }
      }
    }
    return _mutationMap;
  };

  return {
    onMutate
  };
}

function findItemInPages<Data>(pages: Page<Data>[], key: keyof Data, value: unknown) {
  for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
    const page = pages[pageIndex];
    for (let dataIndex = 0; dataIndex < page.data.length; dataIndex++) {
      const item = page.data[dataIndex];
      if (item[key] === value) {
        return { pageIndex, dataIndex };
      }
    }
  }
  return undefined;
}

function getItemFromPage<Data>(pages: Page<Data>[], pageIndex: number, dataIndex: number) {
  return pages[pageIndex].data[dataIndex];
}
