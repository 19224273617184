import dayjs from "dayjs";

function getYear(date: string, locale: string) {
  const year = dayjs(date).get("year");
  const currentYear = dayjs().get("year");
  if (year === currentYear) return "";

  switch (locale) {
    case "de":
      return ` ${year}`;
    case "en":
    default:
      return `, ${year}`;
  }
}

function getDate(date: string, month: string, locale: string) {
  const year = getYear(date, locale);
  const format = locale === "de" ? `D. ${month}` : `${month} D`;
  const dateWithoutYear = dayjs(date).format(format);
  const dateComplete = (month === "MMM" ? dateWithoutYear.slice(0, -1) : dateWithoutYear) + year;
  return dateComplete;
}

export function normalizeShortId(locale: string, shortId?: string | null) {
  if (!shortId) return { short: "", long: "" };

  const idArray = shortId.split("-");
  const dateShort = getDate(idArray[0], "MMM", locale);
  const dateLong = getDate(idArray[0], "MMMM", locale);

  return {
    short: `${dateShort} / ${idArray[1]}`,
    long: `${dateLong} / ${idArray[1]}`
  };
}

export default function useSubmissionShortId(shortId: ComputedRef<string | null | undefined>) {
  const { locale } = useI18n();
  return computed(() => {
    if (!shortId.value) return { short: "", long: "" };
    return normalizeShortId(locale.value, shortId.value);
  });
}
