import type { InsertUserClaimStatusOneMutationVariables, GetClaimByIdQuery } from "#gql";

import { useQueryClient, useMutation } from "@tanstack/vue-query";

import type { GqlErrorHandlingOptions } from "../useGqlErrorHandling.js";

type Options = {
  errorHandling: GqlErrorHandlingOptions;
};
type Claim = GetClaimByIdQuery["claim"];
type MutationProps = Omit<InsertUserClaimStatusOneMutationVariables, "userId">;

export default function useUserClaimStatusInsert(options?: Options) {
  const queryClient = useQueryClient();
  const { onError } = useCacheOnErrorRevert();
  const { onMutate } = useCacheOptimisticUpdate<
    InsertUserClaimStatusOneMutationVariables,
    NonNullable<Claim>
  >(updateDraft);
  const { onMutate: onMutateFilters } = useCacheInfinityOptimisticUpdate<MutationProps, NonNullable<Claim>>(
    "id",
    updateDraft
  );
  const context = useMutation({
    mutationKey: [MUTATION_KEYS.claimStatusUpdate],
    mutationFn: async (vars: MutationProps) => {
      return await GqlInsertUserClaimStatusOne({
        claimId: vars.claimId,
        hasRead: vars.hasRead,
        hasMarked: vars.hasMarked
      });
    },
    onMutate: async (vars) => {
      let mutationMap = await onMutate(vars, [[QUERY_KEYS.claim, vars.claimId]]);
      mutationMap = await onMutateFilters(vars, [[QUERY_KEYS.claims]], mutationMap, vars.claimId);

      return { mutationMap };
    },
    onError,
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.claims], exact: false });
    }
  });
  useGqlErrorHandling(context.error, context.data, options?.errorHandling);
  return context;
}
function updateDraft(vars: MutationProps, draft: NonNullable<Claim>) {
  draft.userClaimStatuses = [{ hasRead: vars.hasRead, hasMarked: vars.hasMarked }];
}
