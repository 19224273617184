<script lang="ts" setup>
type UserSatusButtonProps = {
  claimId?: string;
  size?: "xs" | "sm" | "default";
  userStatus?: {
    hasMarked: boolean;
    hasRead: boolean;
  };
};
const props = defineProps<UserSatusButtonProps>();
const { mutate: mutateHasMarked, isPending: isPendingHasMarked } = useUserClaimStatusHasMarkedUpdate();
const { mutate: insertUserClaimStatus, isPending: isPendingInsertUserClaimStatus } =
  useUserClaimStatusInsert();

function toggleUserhasMarked() {
  if (props.userStatus) {
    mutateHasMarked({ claimId: props.claimId, hasMarked: !props.userStatus.hasMarked });
  } else {
    insertUserClaimStatus({
      claimId: props.claimId,
      hasMarked: true,
      hasRead: false
    });
  }
}
</script>
<template>
  <BasicButton
    :size="size || 'sm'"
    :variant="userStatus?.hasMarked ? 'markedActive' : 'white'"
    :icon="ICONS.bookmark"
    :tooltip="userStatus?.hasMarked ? 'claim.unmark' : 'claim.mark'"
    :loading="isPendingHasMarked || isPendingInsertUserClaimStatus"
    @click.prevent.stop="toggleUserhasMarked"
  />
</template>
