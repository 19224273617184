import type { UpdateClaimInternalMutationVariables, GetClaimByIdQuery } from "#gql";

import { useMutation } from "@tanstack/vue-query";

import type { GqlErrorHandlingOptions } from "../useGqlErrorHandling.js";

type Options = {
  errorHandling: GqlErrorHandlingOptions;
};
type Claim = GetClaimByIdQuery["claim"];
export default function useClaimInternalUpdate(options?: Options) {
  const { onError } = useCacheOnErrorRevert();
  const { onMutate: onSingleUpdate } = useCacheOptimisticUpdate<
    UpdateClaimInternalMutationVariables,
    NonNullable<Claim>
  >(update);

  const { onMutate: onFilterUpdate } = useCacheInfinityOptimisticUpdate<
    UpdateClaimInternalMutationVariables,
    NonNullable<Claim>
  >("id", update);
  const context = useMutation({
    mutationKey: [MUTATION_KEYS.claimInternalUpdate],
    mutationFn: async (vars: UpdateClaimInternalMutationVariables) => {
      return await GqlUpdateClaimInternal({ id: vars.id, internal: vars.internal });
    },
    onMutate: async (vars) => {
      let mutationMap = await onSingleUpdate(vars, [[QUERY_KEYS.claim, vars.id]]);
      mutationMap = await onFilterUpdate(vars, [[QUERY_KEYS.claims]], mutationMap, vars.id);
      return { mutationMap };
    },
    onError
  });
  useGqlErrorHandling(context.error, context.data, options?.errorHandling);
  return context;
}

function update(vars: UpdateClaimInternalMutationVariables, draft: NonNullable<Submission>) {
  draft.internal = vars.internal;
}
